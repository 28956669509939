import React, { Component } from 'react';
import { TextField, Button, Card, Typography } from '@material-ui/core';
import axios from 'axios';

const inputStyle = {
    width: '40vw'
}

const buttonStyle = {
    marginRight: 'auto',
    marginTop: '15px'
}

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        }
    }

    handleChange(e) {
        this.setState({ email: e.target.value })
    }

    handleSubmit = (e, body) => {
        e.preventDefault();
        axios.post('/forgotpassword', body)
            .then((res) => {
                console.log(res)
            })
            .catch((error) => {
                alert('Please check your email')
            })
    }

    render() {
        console.log('forgotpassword state', this.state)
        return (
            <div style={{ 'text-align': 'center', marginTop: '200px' }}>
                {/* <Card style={{width: '60%', height: '60%', margin: 'auto', padding: '20px'}}> */}
                <form
                    method='post'
                    onSubmit={(e) => this.handleSubmit(e, this.state)}>
                    <div style={{ marginBottom: '50px' }}>
                        <Typography variant='h5'>Forgot Your Password?</Typography>
                        <Typography variant='caption'>Enter your email address below and we'll send you a link to retrieve your password.</Typography>
                    </div>
                    <TextField
                        label="Email Address"
                        type='text'
                        value={this.state.email}
                        onChange={e => this.handleChange(e)}
                        variant='outlined'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={inputStyle}
                    />
                    <br />
                    <Button
                        type='submit'
                        style={buttonStyle}
                        color='primary'
                    >
                        Retrieve Password
                            </Button>
                </form>
                {/* </Card> */}
                <Button
                    onClick={() => window.location.assign('/login')}
                    style={{ marginTop: '60px' }}
                    size='small'
                    color='primary'
                >
                    Back to Login
                </Button>
            </div>
        )
    }
}
