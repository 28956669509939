import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from './components/login'
import ForgotPassword from "./components/forgotPassword";

export const RedirectRoute = process.env.REACT_APP_REDIRECT_ROUTE

function App() {
  return (
    <div>
      <Switch>
        <Route exact path='/'>
            <Redirect to='/login' />
        </Route>
        <Route exact path='/login' component={Login} />
        <Route exact path='/forgotpassword' component={ForgotPassword} />
      </Switch>
    </div>
  );
}

export default App;
